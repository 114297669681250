import PropTypes from "prop-types";
import React, { useState } from "react";
import NewsData from "../../data/news.json";

const NewsContainer = ({ currentLanguage }) => {
    const [activeModalIndex, setActiveModalIndex] = useState(null);

    const toggleModal = (index) => {
        if (activeModalIndex === index) {
            setActiveModalIndex(null);
        } else {
            setActiveModalIndex(index);
        }
    };

    const newsItems = NewsData[0] || [];
    const totalItems = newsItems.length;

    const emptySlots = (4 - (totalItems % 4)) % 4;

    return (
        <div className="service-section mt-7">
            <div className="container text-center mb-3">
                <h2>NEWS</h2>
            </div>
            <div className="container mx-auto">
                <div className="row row-cols-lg-4 row-cols-1 row-cols-md-2">
                    {newsItems.map((single, index) => (
                        <div
                            className="news-article mx-auto mb-2"
                            onClick={() => toggleModal(index)}
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    toggleModal(index);
                                }
                            }}
                            key={index}
                            data-key={index}
                        >
                            <div className="card news-content h-100 shadow col-12 col-md-12 mx-auto p-3 d-flex justify-content-between align-items-center">
                                <img
                                    src={single.img}
                                    alt={single.title[currentLanguage]}
                                    className="card-img-top rounded"
                                    width={320}
                                    height={140}
                                    style={{ objectFit: "cover" }}
                                />
                                <div className="mt-4">
                                    <div className="d-flex">
                                        <span className="news-tag">
                                            {single.tag}
                                        </span>
                                        ・
                                        <span className="news-date">
                                            {single.date}
                                        </span>
                                    </div>

                                    <span className="news-text">
                                        {single.title[currentLanguage]}
                                    </span>
                                </div>
                            </div>

                            {activeModalIndex === index && (
                                <>
                                    <div className="blur-background"></div>

                                    <div
                                        className="modal show d-block"
                                        tabIndex="-1"
                                        role="dialog"
                                    >
                                        <div
                                            className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                                            role="document"
                                        >
                                            <div className="modal-content">
                                                <div className="modal-header mb-5">
                                                    <h5 className="modal-title">
                                                        {
                                                            single.title[
                                                                currentLanguage
                                                            ]
                                                        }
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        onClick={() =>
                                                            toggleModal(index)
                                                        }
                                                    ></button>
                                                </div>
                                                <div className="modal-body black-color">
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: single
                                                                .detail[
                                                                currentLanguage
                                                            ],
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}

                    {/* 足りない数の空のコンテナを追加 */}
                    {Array.from({ length: emptySlots }).map((_, index) => (
                        <div
                            key={`empty-${index}`}
                            className="news-article mx-auto mb-2 invisible"
                        >
                            <div className="card news-content h-100 shadow col-12 col-md-12 mx-auto p-3 d-flex justify-content-between align-items-center"></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

NewsContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default NewsContainer;
