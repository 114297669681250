import PropTypes from "prop-types";

const Brand = ({ data, currentLanguage }) => {
    return (
        <div className="single-brand">
            <img src={process.env.PUBLIC_URL + data.image} alt="brand logo" />
            {data.label && <p>{data.label[currentLanguage]}</p>}
        </div>
    );
};

Brand.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
};

export default Brand;
