import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import RecruitData from "../data/recruit.json";
import RecruitContainer from "../containers/recruit";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const RecruitPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.careers.title[currentLanguage]}
                    description={SEOData.careers.description[currentLanguage]}
                    keywords={SEOData.careers.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={RecruitData[0].banner[0].title[currentLanguage]}
                        excerpt={
                            RecruitData[0].banner[0].excerpt[currentLanguage]
                        }
                        image="/images/banner/1.png"
                    />
                    <RecruitContainer currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

RecruitPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default RecruitPage;
