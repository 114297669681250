import { React, useState } from "react";
import Newsletter from "../../../components/newsletter";
import HomeData from "../../../data/home.json";
import SectionTitle from "../../../components/section-title";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom"; // for redirecting
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const NewsletterArea = ({ currentLanguage }) => {
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -200;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };
    return (
        <div className="news-letter-section section-pb pt-9">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-xl-6 mx-auto">
                        <SectionTitle
                            classOption="title-section mb-10 text-center"
                            title={
                                HomeData[12].newsLetterSection[0].title[
                                    currentLanguage
                                ]
                            }
                            titleClassOption={"letterspace-wide"}
                        />
                    </div>
                    <div className="container col-xl-12 col-lg-12 mx-auto">
                        <div className="row row-cols-lg-3 row-cols-md-3 row-cols-1 g-3 d-flex align-items-stretch">
                            {HomeData[12].newsLetterSection[0].sections.map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className="col d-flex align-items-stretch"
                                    >
                                        <HashLink
                                            className="btn rounded-lg w-100 h-100 bg-white black-color shadow py-4 inquery-title"
                                            to={
                                                process.env.PUBLIC_URL +
                                                item.url
                                            }
                                            scroll={(el) =>
                                                scrollWithOffset(el)
                                            }
                                        >
                                            {item.label[currentLanguage]}
                                        </HashLink>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

NewsletterArea.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default NewsletterArea;
