import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, currentLanguage }) => {
    const languageMap = {
        jp: "ja",
        cn: "zh",
    };

    const mappedLang = languageMap[currentLanguage] || currentLanguage;
    const path = window.location.pathname;

    const languages = ["ja", "en", "zh", "ru"];

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={`https://marvel.engineer${path}`} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta
                property="og:url"
                content={`https://marvel.engineer${path}`}
            />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="keywords" content={keywords} />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "${title}",
                        "url": "https://marvel.engineer${path}",
                        "logo": "https://marvel.engineer/favicon.ico",
                        "inLanguage": "${mappedLang}"
                    }
                `}
            </script>
            <meta httpEquiv="Content-Language" content={mappedLang} />
            {languages.map((lang) => (
                <link
                    key={lang}
                    rel="alternate"
                    hrefLang={lang}
                    href={`https://marvel.engineer/${lang}${path}`}
                />
            ))}

            <link
                rel="alternate"
                hrefLang="x-default"
                href={`https://marvel.engineer${path}`}
            />
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired,
};

export default SEO;
