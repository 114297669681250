import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AccordionContainer from "../containers/accordion";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import SEOData from "../data/seo.json";

const FaqPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.faq.title[currentLanguage]}
                    description={SEOData.faq.description[currentLanguage]}
                    keywords={SEOData.faq.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title="FAQ"
                        excerpt="よくある質問をご紹介します"
                        image="/images/faq/1.png"
                    />
                    <AccordionContainer currentLanguage={currentLanguage} />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

FaqPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default FaqPage;
