import { useEffect, useRef } from "react";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import PropTypes from "prop-types";
import CareersData from "../../data/careers.json";

const RecruitContainer = ({ currentLanguage }) => {
    return (
        <>
            <div className={`feature-section position-relative section-pt`}>
                <div className="container custom-container">
                    <div className="row g-0 align-items-center">
                        <div className="col-xl-12 col-lg-12 mx-auto mb-3 text-center">
                            <SectionTitle
                                classOption="title-section "
                                subTitle={
                                    CareersData[0].subtitle[currentLanguage]
                                }
                                title={CareersData[0].title[currentLanguage]}
                                excerptClassOption="mb-10 happy-customer"
                                titleClassOption="letterspace-wide"
                                excerpt={
                                    CareersData[0].excerpt[currentLanguage]
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="container">
                    <div className="row mb-7 align-items-center">
                        <div className="col-xl-6 col-md-7 mb-7 order-lg-1 order-2">
                            <SectionTitle
                                classOption="title-section sub-section-title letterspace-wide"
                                title={CareersData[1][0].title[currentLanguage]}
                            />
                            <p className="presidentmessage-text mb-5">
                                {CareersData[1][0].content[currentLanguage]}
                            </p>
                            <a
                                href="https://recruit.marvel.engineer/"
                                className="btn btn-primary"
                            >
                                APPLY NOW
                            </a>
                        </div>
                        <div className="col-xl-6 col-md-5 mb-7 order-lg-2 order-1">
                            <img
                                src="/images/careers/1.jpg"
                                alt="Web Developer"
                                className="recruit-image"
                            />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center text-center mb-9">
                        <a
                            href="/faq"
                            className="btn btn-primary col-8 col-md-4"
                        >
                            {CareersData[2].faq_button[currentLanguage]}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

RecruitContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default RecruitContainer;
