import React from "react";

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";
import PropTypes from "prop-types";

const ContactContainer = ({ currentLanguage, category }) => {
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-7 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">
                                {
                                    ContactData[1].contactContainer.title[
                                        currentLanguage
                                    ]
                                }
                            </h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: ContactData[1].contactContainer
                                        .excerpt[currentLanguage],
                                }}
                            ></p>
                        </div>

                        <ContactForm
                            currentLanguage={currentLanguage}
                            category={category}
                        />
                    </div>

                    <div className="col-xl-4 col-lg-5 mb-7 offset-xl-1">
                        <div className="contact-address text-center">
                            {ContactData[0].contactInfo &&
                                ContactData[0].contactInfo.map(
                                    (single, key) => {
                                        return (
                                            <ContactInfo
                                                key={key}
                                                data={single}
                                                currentLanguage={
                                                    currentLanguage
                                                }
                                            />
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContactContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    category: PropTypes.string,
};

export default ContactContainer;
