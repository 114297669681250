import React from "react";
import PropTypes from "prop-types";

const ContactInfo = ({ data, currentLanguage }) => {
    return (
        <div className={data.classOption}>
            <h4 className="title presidentmessage-text">
                {data.title[currentLanguage]}
            </h4>
            <p
                className="presidentmessage-text"
                dangerouslySetInnerHTML={{ __html: data.info[currentLanguage] }}
            ></p>
        </div>
    );
};

ContactInfo.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
};

export default ContactInfo;
