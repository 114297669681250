import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Projects from "../../data/projects.json";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProjectsCarouselContainer = ({ classOption, currentLanguage }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2500,
        autoplay: true,
        cssEase: "linear",
    };
    return (
        <Slider {...settings}>
            {Projects[0].slider.map((projectItem, index) => {
                if (projectItem.isForShowCase) {
                    return (
                        <div
                            className={`carousel-item ${classOption}`}
                            data-key={index}
                            key={index}
                        >
                            <div className="carousel-container">
                                <div className="carousel-image-container">
                                    <img
                                        src={projectItem.thumbnail}
                                        alt={projectItem.title[currentLanguage]}
                                    />
                                </div>
                                <div className="carousel-text-container">
                                    <div className="carousel-text-container__header">
                                        {projectItem.title[currentLanguage]}
                                    </div>
                                    <div className="carousel-text-container__content">
                                        {projectItem.text[currentLanguage]}
                                        <div>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/project-details/" +
                                                    projectItem.id
                                                }
                                            >
                                                <button className="btn btn-md btn-primary mt-5 mb-2">
                                                    View Details
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
        </Slider>
    );
};

ProjectsCarouselContainer.propTypes = {
    classOption: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired,
};

export default ProjectsCarouselContainer;
