import React from "react";
import SectionTitle from "../../../components/section-title";
import ServiceBox from "../../../components/service-box";
import ServiceData from "../../../data/service.json";
import HomeData from "../../../data/home.json";
import PropTypes from "prop-types";

const ServiceListContainer = ({ currentLanguage }) => {
    return (
        <div className="service-section section-pt position-relative px-5 px-md-0">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg- mx-auto">
                        <SectionTitle
                            classOption="title-section pb-10 text-center"
                            subTitle={
                                HomeData[8].serviceSection[0].subtitle[
                                    currentLanguage
                                ]
                            }
                            title={
                                HomeData[8].serviceSection[0].title[
                                    currentLanguage
                                ]
                            }
                            excerptClassOption="happy-customer"
                            excerpt={
                                HomeData[8].serviceSection[0].excerpt[
                                    currentLanguage
                                ]
                            }
                            titleClassOption={"letterspace-wide"}
                        />
                    </div>
                </div>

                <div className="row mb-n7 align-items-center">
                    <div className="col-md-7 col-xl-8 mb-6">
                        <div className="service-media-wrapper media-spacing-left">
                            <SectionTitle
                                classOption="pb-10"
                                titleClassOption={"fs-3 fw-bold"}
                                title={
                                    HomeData[15].serviceTitles[0].title[
                                        currentLanguage
                                    ]
                                }
                            />
                            {ServiceData &&
                                ServiceData.slice(0, 3).map((single, index) => {
                                    return (
                                        <ServiceBox
                                            data={single}
                                            key={index}
                                            currentLanguage={currentLanguage}
                                            index={index}
                                        />
                                    );
                                })}
                            <SectionTitle
                                classOption="pb-10"
                                titleClassOption={"fs-3 fw-bold"}
                                title={
                                    HomeData[15].serviceTitles[1].title[
                                        currentLanguage
                                    ]
                                }
                            />
                            {ServiceData &&
                                ServiceData.slice(3, 5).map((single, index) => {
                                    return (
                                        <ServiceBox
                                            data={single}
                                            key={index}
                                            currentLanguage={currentLanguage}
                                            index={index}
                                        />
                                    );
                                })}
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-5 col-12 mb-6 order-md-1 order-xl-0">
                        <div className="service-media-img text-center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/service/media.png`}
                                alt="images_not_found"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceListContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default ServiceListContainer;
