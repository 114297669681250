import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ContactData from "../../data/contact.json";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ currentLanguage, category }) => {
    const inputFields = ContactData[3].contactForm.inputBox;
    const isOdd = inputFields.length % 2 !== 0;
    const recaptchaRef = React.createRef();
    const [selectedOption, setSelectedOption] = useState();
    const [message, setMessage] = useState();

    const { register, handleSubmit, control, errors, reset } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        if (category) setSelectedOption(category);
    }, [category]);

    const onSubmit = async (formData) => {
        try {
            const token = await recaptchaRef.current.getValue();

            formData["g_recaptcha_response"] = token;

            const response = await fetch(
                "https://dndecyz93g.execute-api.ap-northeast-1.amazonaws.com/v1/send",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            const result = await response.json();
            const parsedBody = JSON.parse(result.body);
            setMessage(parsedBody.message);
        } catch (error) {
            const parsedBody = JSON.parse(error.body);
            setMessage(parsedBody.message);
        }
    };
    return (
        <Fragment>
            <form
                id="contactForm"
                className="row"
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
            >
                <div className="col-12 mb-7">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-5 top-50">
                            {
                                ContactData[3].contactForm.radioBox.title[
                                    currentLanguage
                                ]
                            }
                        </div>
                        <div className="col-12 col-lg-7">
                            {ContactData[3].contactForm.radioBox.options.map(
                                (option, index) => (
                                    <div className="form-check" key={index}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id={option.id}
                                            name={
                                                ContactData[3].contactForm
                                                    .radioBox.name
                                            }
                                            value={
                                                option.label[currentLanguage]
                                            }
                                            {...register(
                                                ContactData[3].contactForm
                                                    .radioBox.name,
                                                {
                                                    name: ContactData[3]
                                                        .contactForm.radioBox
                                                        .name,
                                                }
                                            )}
                                            ref={register({
                                                required: "Select category",
                                            })}
                                            checked={
                                                selectedOption === option.id
                                            }
                                            onChange={() =>
                                                setSelectedOption(option.id)
                                            }
                                        />
                                        <label
                                            className="form-check-label main-text"
                                            htmlFor={option.id}
                                        >
                                            {option.label[currentLanguage]}
                                        </label>
                                    </div>
                                )
                            )}
                            {errors[
                                ContactData[3].contactForm.radioBox.name
                            ] && (
                                <p>
                                    {
                                        errors[
                                            ContactData[3].contactForm.radioBox
                                                .name
                                        ].message
                                    }
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                {/* Input Fields Section */}
                {inputFields.map((input, index) => {
                    // Apply a different class for the last field if the number of fields is odd
                    const inputClass =
                        isOdd && index === inputFields.length - 1
                            ? "col-12 col-sm-12 mb-7"
                            : "col-12 col-sm-6 mb-7";

                    return (
                        <div className={inputClass} key={input.id}>
                            <input
                                type="text"
                                className="form-control"
                                id={input.id}
                                name={input.name}
                                placeholder={input.placeholder[currentLanguage]}
                                ref={register({
                                    required:
                                        input.required &&
                                        input.required_label[currentLanguage],
                                    pattern: input.pattern && {
                                        value: new RegExp(input.pattern_value),
                                        message:
                                            input.pattern_label[
                                                currentLanguage
                                            ],
                                    },
                                })}
                                {...register(input.name)}
                            />
                            {errors[input.name] && (
                                <p>{errors[input.name].message}</p>
                            )}
                        </div>
                    );
                })}

                {/* Textarea Field Section */}
                {ContactData[3].contactForm.textareaBox.map((textarea) => (
                    <div className="col-12 mb-9" key={textarea.id}>
                        <textarea
                            className="form-control massage-control"
                            name={textarea.name}
                            id={textarea.id}
                            cols="30"
                            rows="10"
                            placeholder={textarea.placeholder[currentLanguage]}
                            ref={register({
                                required:
                                    textarea.required &&
                                    textarea.required_label[currentLanguage],
                            })}
                        ></textarea>
                        {errors[textarea.name] && (
                            <p>{errors[textarea.name].message}</p>
                        )}
                    </div>
                ))}
                <div className="col-12">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeEWT8qAAAAAKB4uYPgshIx8DvbNHz-8NNed6Dn"
                    />
                </div>
                {message && <div className="col-12">{message}</div>}

                <div className="col-12 mt-5">
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                        data-complete-text="Well Done!"
                    >
                        Send Message
                    </button>
                </div>
            </form>
        </Fragment>
    );
};

ContactForm.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    category: PropTypes.string,
};

export default ContactForm;
