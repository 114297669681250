import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";

const ServiceBox = ({ data, currentLanguage, index }) => {
    const serviceBoxRef = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };

        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.style.transitionDelay = `${index * 0.4}s`;
                    entry.target.classList.add("show");
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        if (serviceBoxRef.current) {
            observer.observe(serviceBoxRef.current);
        }

        return () => {
            if (serviceBoxRef.current) {
                observer.unobserve(serviceBoxRef.current);
            }
        };
    }, [index]);

    return (
        <div className="service-media fade-in-left" ref={serviceBoxRef}>
            <i className={`logo ${data.icon_class}`} />
            <div className="service-media-body">
                <h4 className="title fs-4 fw-bold">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/service-details/${slugify(data.id)}`
                        }
                    >
                        {data.title[currentLanguage]}
                    </Link>
                </h4>
                <p className="presidentmessage-text">
                    {data.excerpt[currentLanguage]}
                </p>
            </div>
        </div>
    );
};

ServiceBox.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default ServiceBox;
