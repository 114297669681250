import PropTypes from "prop-types";
import SectionTitle from "../section-title";
import PhilosophyData from "../../data/philosophy.json";
import React, { useEffect, useRef } from "react";

const CorporatePhilosophy = ({ currentLanguage }) => {
    const sectionRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        };

        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show");
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        sectionRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            sectionRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);

    return (
        <section className="container feature-section position-relative section-pt">
            {PhilosophyData[1].values.map((item, index) => (
                <div
                    key={item.id}
                    ref={(el) => (sectionRefs.current[index] = el)}
                    className="fade-in"
                >
                    <div className={`title-section mt-5`}>
                        <span className="sub-title">
                            {item.subtitle[currentLanguage]}
                        </span>
                        <div className="title-container">
                            <h3
                                className={`third-title letterspace-wide title mb-1`}
                                dangerouslySetInnerHTML={{
                                    __html: item.title[currentLanguage],
                                }}
                            ></h3>
                            <hr
                                className="mt-0"
                                style={{
                                    width: "13%",
                                    height: "5px",
                                    background:
                                        "linear-gradient(90deg, rgba(156,39,31,1) 0%, rgba(156,39,31,1) 50%, rgba(255,255,255,1) 100%)",
                                    marginBottom: "4rem",
                                }}
                            />
                            {item.sidebody[currentLanguage] && (
                                <span
                                    className="title-sidebody fs-1"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            item.sidebody[currentLanguage] ||
                                            "",
                                    }}
                                ></span>
                            )}
                            <p
                                className="mt-6 main-text"
                                dangerouslySetInnerHTML={{
                                    __html: item.excerpt[currentLanguage] || "",
                                }}
                                style={{ marginBottom: "6rem" }}
                            ></p>
                        </div>
                    </div>
                    {item.id != 4 ? (
                        <></>
                    ) : (
                        <>
                            <section
                                className="w-100 mt-7 row d-flex justify-content-center"
                                style={{ marginBottom: "6rem" }}
                            >
                                {PhilosophyData[1].cards.map((card) => (
                                    <div
                                        key={card.id}
                                        className={`card card-shadow rounded-lg col-8 col-xl-3 ${card.classOption}`}
                                    >
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{ minHeight: "200px" }}
                                        >
                                            <img
                                                src={card.image}
                                                alt="..."
                                                style={{
                                                    maxWidth: "100%",
                                                    height: "auto",
                                                }} // Ensure proper scaling
                                            />
                                        </div>
                                        <div className="card-body text-center d-flex flex-column">
                                            <h4 className="card-title mt-auto text-primary fw-bold letterspace-wide mb-3">
                                                {card.title[currentLanguage]}
                                            </h4>
                                            <p className="card-text small-text black-color letterspace-light">
                                                {card.content[currentLanguage]}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </section>
                        </>
                    )}
                </div>
            ))}
        </section>
    );
};

CorporatePhilosophy.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default CorporatePhilosophy;
