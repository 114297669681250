import React from "react";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";

const CookieConsentForm = ({ currentLanguage }) => {
    const gdprText = {
        en: "This site uses cookies to enhance your experience and analyze our traffic. By clicking 'OK', you agree to our use of cookies.",
        jp: "このサイトでは、ユーザーエクスペリエンスの向上とトラフィックの分析のためにクッキーを使用しています。「OK」をクリックすることで、クッキーの使用に同意したことになります。",
        cn: "本网站使用 cookie 来提升您的体验和分析我们的流量。点击 “OK”，即表示您同意我们使用 cookie。",
        ru: "Этот сайт использует файлы cookie для улучшения вашего опыта и анализа трафика. Нажимая «ОК», вы соглашаетесь с использованием файлов cookie.",
    };

    return (
        <CookieConsent
            buttonText="OK"
            cookieName="jpmarvel_homepage_userConsent"
            disableStyles={true}
            expires={14}
            style={{
                position: "fixed",
                bottom: "0",
                left: "0",
                width: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "#ffffff",
                padding: "20px 10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "1000",
            }}
            contentStyle={{
                backgroundColor: "#ffffff",
                color: "#333333",
                padding: "20px",
                maxWidth: "600px",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                textAlign: "center",
                fontSize: "14px",
                lineHeight: "1.5",
            }}
            buttonStyle={{
                backgroundColor: "#bf1c1a",
                color: "#ffffff",
                borderRadius: "4px",
                padding: "10px 20px",
                margin: "10px 0 0 10px",
                fontSize: "14px",
                cursor: "pointer",
                border: "none",
                transition: "background-color 0.3s",
            }}
            onAccept={() => {
                if (typeof gtag !== "undefined") {
                    gtag("event", "cookie_consent", {
                        event_category: "engagement",
                        event_label: "cookie_consent_accepted",
                    });
                    gtag("consent", "update", {
                        analytics_storage: "granted",
                    });
                }
            }}
        >
            {currentLanguage && gdprText[currentLanguage]
                ? gdprText[currentLanguage]
                : gdprText.en}
        </CookieConsent>
    );
};

CookieConsentForm.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default CookieConsentForm;
