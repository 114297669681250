import PropTypes from "prop-types";
import react, { useEffect } from "react";

const Team = ({ data, currentLanguage }) => {
    return (
        <div className="new-team-members-list">
            <img src={process.env.PUBLIC_URL + data.thumb} alt="images" />
            <h3 className="title">{data.name[currentLanguage]}</h3>
            <span>{data.designation[currentLanguage]}</span>
        </div>
    );
};

Team.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
};

export default Team;
