import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Parallax from "parallax-js";

const Intro = ({ data, currentLanguage, currentImageIndex, animateTitle }) => {
    return (
        <div className="hero-slider">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="hero-slide-content">
                            <div
                                className={`hero-slide-title-wrapper ${
                                    animateTitle ? "animate-title" : ""
                                }`}
                            >
                                <h2 className="title animated">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data.title[currentLanguage],
                                        }}
                                        className="title-wrapper"
                                    ></span>
                                </h2>
                            </div>
                            <div className="hero-slide-buttons-wrapper">
                                <Button
                                    classOption="btn btn-md animated delay1 btn-dark btn-hover-dark me-4 mb-3 mb-sm-0"
                                    text="Get Started"
                                    path="/about"
                                />
                                <Button
                                    classOption={`btn btn-md animated delay2 btn-light btn-black btn-hover-secondary mb-3 mb-sm-0`}
                                    text="Book a Meeting"
                                    path="/contact"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
    currentImageIndex: PropTypes.number.isRequired,
    animateTitle: PropTypes.bool.isRequired,
};

export default Intro;
