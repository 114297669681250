import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ContactData from "../../data/contact.json";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useHistory } from "react-router-dom";

const ContactDialogForm = ({ currentLanguage }) => {
    const history = useHistory();
    const handleClick = (event) => {
        history.push(`/contact`);
    };

    return (
        <>
            <button
                className="contact-dialog-form__button"
                onClick={() => {
                    handleClick();
                }}
            >
                <QuestionMarkCircleIcon className="question-icon" />
            </button>
        </>
    );
};

ContactDialogForm.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default ContactDialogForm;
