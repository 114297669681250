import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import HomeBlog from "../containers/home/blog";
import IntroContainer from "../containers/home/intro";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import ProjectsCarouselContainer from "../components/projects-carousel";
import PropTypes from "prop-types";
import SocialIconContainer from "../components/social";
import CorporatePhilosophy from "../components/corporate-philosophy";
import ValuesComponent from "../components/values";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const HomePage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.homepage.title[currentLanguage]}
                    description={SEOData.homepage.description[currentLanguage]}
                    keywords={SEOData.homepage.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <IntroContainer currentLanguage={currentLanguage} />
                    <BrandContainer currentLanguage={currentLanguage} />
                    <ProjectsCarouselContainer
                        currentLanguage={currentLanguage}
                    />
                    <ValuesComponent
                        currentLanguage={currentLanguage}
                        classOptions={"section-pb"}
                    />
                    <HomeAboutContainer currentLanguage={currentLanguage} />
                    <ServiceListContainer currentLanguage={currentLanguage} />
                    <TestimonialContainer currentLanguage={currentLanguage} />
                    <FunFactContainer
                        currentLanguage={currentLanguage}
                        classOption="mt-lg-0"
                    />
                    <TeamContainer currentLanguage={currentLanguage} />
                    <SocialIconContainer
                        currentLanguage={currentLanguage}
                        classOption="mb-10"
                    />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

HomePage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default HomePage;
