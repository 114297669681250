import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import { useEffect } from "react";
import Logo from "../../logo";
import menuData from "../../../data/header.json";

const MobileMenu = ({ show, onClose, currentLanguage, onSelect }) => {
    useEffect(() => {
        if (show) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
        return () => document.body.classList.remove("no-scroll");
    }, [show]);

    const languageTable = {
        en: {
            en: "English",
            jp: "Japanese",
            cn: "Chinese",
            ru: "Russian",
        },
        jp: {
            en: "英語",
            jp: "日本語",
            cn: "中国語",
            ru: "ロシア語",
        },
        cn: {
            en: "英语",
            jp: "日语",
            cn: "中文简体",
            ru: "俄语",
        },
        ru: {
            en: "Английский",
            jp: "Японский",
            cn: "Китайский",
            ru: "Русский",
        },
    };

    const handleOptionSelect = (option) => {
        onSelect(option);
    };

    const options = _.entries(languageTable[currentLanguage]);

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    return (
        <div className={`offcanvas-modal ${show ? "show" : ""}`}>
            <div className="offcanvas-dialog">
                <div className="menu-content">
                    <div className="offcanvas-header">
                        <Logo
                            classOption="offcanvas-logo d-inline-block"
                            image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        />
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <nav id="offcanvasNav" className="offcanvas-menu">
                        <ul>
                            {menuData[0].header.map((menuItem) => (
                                <li key={menuItem.id}>
                                    <NavLink
                                        exact
                                        to={
                                            process.env.PUBLIC_URL +
                                            menuItem.url
                                        }
                                        className={
                                            menuItem.mobile_className
                                                ? menuItem.mobile_className
                                                : ""
                                        }
                                    >
                                        {menuItem.text[currentLanguage]}
                                    </NavLink>
                                    {menuItem.menu_expand && (
                                        <span
                                            className="menu-expand"
                                            onClick={onClickHandler}
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    {menuItem.submenu && (
                                        <ul>
                                            {menuItem.submenu.map(
                                                (subMenuItem) => (
                                                    <li
                                                        key={subMenuItem.id}
                                                        className="sub-menu-item"
                                                    >
                                                        <NavLink
                                                            to={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                subMenuItem.url
                                                            }
                                                        >
                                                            {
                                                                subMenuItem
                                                                    .text[
                                                                    currentLanguage
                                                                ]
                                                            }
                                                        </NavLink>
                                                        {subMenuItem.menu_expand && (
                                                            <span
                                                                className="menu-expand"
                                                                onClick={
                                                                    onClickHandler
                                                                }
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        {subMenuItem.subsubmenu && (
                                                            <ul>
                                                                {subMenuItem.subsubmenu.map(
                                                                    (
                                                                        subMenuItem
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                subMenuItem.id
                                                                            }
                                                                            className="sub-menu-item"
                                                                        >
                                                                            <NavLink
                                                                                to={
                                                                                    process
                                                                                        .env
                                                                                        .PUBLIC_URL +
                                                                                    subMenuItem.url
                                                                                }
                                                                            >
                                                                                {
                                                                                    subMenuItem
                                                                                        .text[
                                                                                        currentLanguage
                                                                                    ]
                                                                                }
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        )}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                </li>
                            ))}
                            <li>
                                <NavLink to="/">Languages</NavLink>
                                <span
                                    className="menu-expand"
                                    onClick={onClickHandler}
                                    aria-hidden="true"
                                ></span>
                                <ul>
                                    {options.map((option) => {
                                        const selected =
                                            option[0] === currentLanguage;
                                        const nativeVersion =
                                            languageTable[option[0]][option[0]];
                                        return (
                                            <li key={option[0]}>
                                                <NavLink
                                                    to="/"
                                                    role="button"
                                                    tabIndex={0}
                                                    onClick={() =>
                                                        handleOptionSelect(
                                                            option[0]
                                                        )
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === "Enter" ||
                                                            e.key === " "
                                                        ) {
                                                            handleOptionSelect(
                                                                option[0]
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {option[1]} ({nativeVersion}
                                                    )
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    currentLanguage: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default MobileMenu;
