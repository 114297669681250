import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SectionTitle from "../section-title";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import CustomFileRender from "../../utils/customFileRenderer";

const PreviewContainer = ({ currentLanguage }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fileUrl, setFileUrl] = useState(
        "image.marvel.engineer/corporate profile.pdf"
    );

    const title = {
        en: "CORPORATE PROFILE",
        jp: "CORPORATE PROFILE",
        cn: "CORPORATE PROFILE",
        ru: "КОРПОРАТИВНЫЙ ПРОФИЛЬ",
    };

    const excerpt = {
        en: "Here is a profile of Marvel",
        jp: "Marvelのプロフィールを紹介します",
        cn: "以下是Marvel的简介",
        ru: "Здесь представлен профиль Marvel",
    };

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-10 mx-auto">
                        <SectionTitle
                            classOption="mb-2 pb-10 text-center"
                            excerpt={excerpt[currentLanguage]}
                            title={title[currentLanguage]}
                            titleClassOption="values-title"
                        />
                    </div>
                </div>
                <div className="row mb-n7 align-items-center">
                    <div className="col-md-8 col-xl-10 mb-10 mx-auto">
                        <DocViewer
                            documents={[{ uri: fileUrl, fileType: "pdf" }]}
                            pluginRenderers={[
                                CustomFileRender,
                                ...DocViewerRenderers,
                            ]}
                            style={{ height: "500px" }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

PreviewContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default PreviewContainer;
