import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import LanguageDropdown from "../../language-dropdown";
import menuData from "../../../data/header.json";
import { NavHashLink } from "react-router-hash-link";

const MainMenu = ({ currentLanguage, onSelect }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isActiveMenu = (menuItem) => {
        if (menuItem.url === currentPath) {
            return true;
        }
        if (menuItem.submenu) {
            return menuItem.submenu.some(
                (subMenuItem) =>
                    subMenuItem.url === currentPath ||
                    (subMenuItem.subsubmenu &&
                        subMenuItem.subsubmenu.some(
                            (subSubMenuItem) =>
                                subSubMenuItem.url === currentPath
                        ))
            );
        }
        return false;
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -100;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    useEffect(() => {
        // 各サブメニューの項目数に応じてカラム数を調整
        const adjustSubMenuColumns = () => {
            const subMenus = document.querySelectorAll(".sub-menu");
            const subsubMenus = document.querySelectorAll(".sub-sub-menu");

            subMenus.forEach((subMenu) => {
                const items = subMenu.querySelectorAll("li").length;

                if (items > 10) {
                    subMenu.style.gridTemplateColumns = "repeat(3, 1fr)";
                } else if (items > 5) {
                    subMenu.style.gridTemplateColumns = "repeat(2, 1fr)";
                    subMenu.style.width = "350px";
                } else {
                    subMenu.style.gridTemplateColumns = "repeat(1, 1fr)";
                    subMenu.style.width = "250px";
                }
            });
            subsubMenus.forEach((subsubMenu) => {
                const items = subsubMenu.querySelectorAll("li").length;

                if (items > 10) {
                    subsubMenu.style.gridTemplateColumns = "repeat(3, 1fr)";
                } else if (items > 5) {
                    subsubMenu.style.gridTemplateColumns = "repeat(2, 1fr)";
                    subsubMenu.style.width = "350px";
                } else {
                    subsubMenu.style.gridTemplateColumns = "repeat(1, 1fr)";
                    subsubMenu.style.width = "250px";
                }
            });
        };

        // サブメニューのカラム数を調整
        adjustSubMenuColumns();

        // リサイズイベントに対応（オプション）
        window.addEventListener("resize", adjustSubMenuColumns);

        // クリーンアップ
        return () => {
            window.removeEventListener("resize", adjustSubMenuColumns);
        };
    }, []); // 初回のみ実行
    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex">
                {menuData[0].header.map((menuItem) => (
                    <li
                        key={menuItem.id}
                        className={
                            isActiveMenu(menuItem) ? "active-menu-item" : ""
                        }
                    >
                        <NavLink
                            exact
                            to={process.env.PUBLIC_URL + menuItem.url}
                            className={
                                menuItem.className ? menuItem.className : ""
                            }
                        >
                            {menuItem.text[currentLanguage]}
                        </NavLink>
                        {menuItem.submenu && (
                            <ul className="sub-menu">
                                {menuItem.submenu.map((subMenuItem) => (
                                    <li
                                        key={subMenuItem.id}
                                        className="sub-menu-item"
                                    >
                                        <NavHashLink
                                            className="sub-menu-link"
                                            to={
                                                process.env.PUBLIC_URL +
                                                subMenuItem.url
                                            }
                                            scroll={(el) =>
                                                scrollWithOffset(el)
                                            }
                                        >
                                            {subMenuItem.text[currentLanguage]}
                                        </NavHashLink>
                                        {subMenuItem.subsubmenu && (
                                            <ul className="sub-sub-menu">
                                                {subMenuItem.subsubmenu.map(
                                                    (subSubMenuItem) => (
                                                        <li
                                                            key={
                                                                subSubMenuItem.id
                                                            }
                                                            className="sub-sub-menu-item"
                                                        >
                                                            <NavHashLink
                                                                className="sub-sub-menu-link"
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    subSubMenuItem.url
                                                                }
                                                                scroll={(el) =>
                                                                    scrollWithOffset(
                                                                        el
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    subSubMenuItem
                                                                        .text[
                                                                        currentLanguage
                                                                    ]
                                                                }
                                                            </NavHashLink>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                <li>
                    <LanguageDropdown
                        onSelect={onSelect}
                        currentLanguage={currentLanguage}
                    />
                </li>
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default MainMenu;
