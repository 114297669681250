import PropTypes from "prop-types";

const CustomFileRender = ({ mainState: { currentDocument } }) => {
    if (!currentDocument) return null;

    const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
        currentDocument.uri
    )}&embedded=true&widget=true`;

    return (
        <div style={{ width: "100%" }} id="my-doc-renderer">
            <iframe
                className={"doc"}
                width="100%"
                height="100%"
                src={googleDocsViewerUrl}
                frameBorder="0"
                title="Google viewer"
            ></iframe>
        </div>
    );
};

// Define the file types that this renderer will handle
CustomFileRender.fileTypes = [
    "pdf",
    "application/pdf",
    "xls",
    "application/vnd.ms-excel",
    "xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "ppt",
    "application/vnd.ms-powerpoint",
    "pptx",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "doc",
    "application/msword",
    "docx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
CustomFileRender.weight = 1;

CustomFileRender.propTypes = {
    mainState: PropTypes.shape({
        currentDocument: PropTypes.shape({
            uri: PropTypes.string.isRequired, // Ensure uri is a required string
        }),
    }).isRequired,
};

export default CustomFileRender;
