import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const TeamCard = ({ data, currentLanguage }) => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div
            className="team-card"
            onClick={toggleModal}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                    toggleModal();
                }
            }}
        >
            <div className="thumb">
                <img
                    src={process.env.PUBLIC_URL + data.thumb}
                    alt="img"
                    className="rounded"
                />
            </div>
            <div className="content">
                <span>{data.designation_abbr[currentLanguage]}</span>
                <h3
                    className="title"
                    dangerouslySetInnerHTML={{
                        __html: data.name[currentLanguage],
                    }}
                ></h3>
            </div>

            <Modal
                show={showModal}
                onHide={toggleModal}
                centered
                size="xl"
                backdropClassName="blur-background"
            >
                <Modal.Body className="row p-0 rounded">
                    <div className="col-12 col-md-6 h-100">
                        <img
                            src={process.env.PUBLIC_URL + data.back}
                            alt="img"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-12 col-md-6 text-start pt-10 px-10">
                        <h5
                            className="designation-title letterspace-wide-xl"
                            dangerouslySetInnerHTML={{
                                __html: data.designation[currentLanguage],
                            }}
                        ></h5>
                        <h4
                            className="team-name letterspace-wide mt-3 mt-md-5"
                            dangerouslySetInnerHTML={{
                                __html: data.name[currentLanguage],
                            }}
                        ></h4>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: data.detail[currentLanguage],
                            }}
                            className="mt-5 mb-9 team-text letterspace-wide"
                        ></p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

TeamCard.propTypes = {
    data: PropTypes.object.isRequired,
    currentLanguage: PropTypes.string.isRequired,
};

export default TeamCard;
