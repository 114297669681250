import React from "react";
import PropTypes from "prop-types";

const ServiceDetails = ({ data, currentLanguage }) => {
    return (
        <div className="service-wrap">
            <div className="bg-light text-center">
                <img src={`${data.image}`} alt="" />
            </div>
            <div className="title-section">
                <h3 className="service-title pb-2">
                    {data.title[currentLanguage]}
                </h3>
                <div className="service-content-wrap">
                    {data.body.map((single, key) => {
                        return (
                            <div
                                className="desc"
                                key={key}
                                dangerouslySetInnerHTML={{
                                    __html: single[currentLanguage],
                                }}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

ServiceDetails.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
};

export default ServiceDetails;
