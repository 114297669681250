import PropTypes from "prop-types";

const Testimonial = ({ data, currentLanguage }) => {
    return (
        <div className="testimonial-slide-item swiper-slide">
            <span className="quote zmdi zmdi-quote text-primary"></span>
            <p className="testimonial-text mt-2 mb-5">
                {data.excerpt[currentLanguage]}
            </p>
            <div className="avater d-flex">
                <div className="avater-profile">
                    <img
                        src={process.env.PUBLIC_URL + data.authorThumb}
                        alt="testimonial profile"
                    />
                </div>
                <div className="avater-info">
                    <p>{data.name[currentLanguage]}</p>
                    <span>{data.designation[currentLanguage]}</span>
                </div>
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
    currentLanguage: PropTypes.string.isRequired,
};

export default Testimonial;
