import PropTypes from "prop-types";
import PhilosophyData from "../../data/philosophy.json";
import SectionTitle from "../section-title";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useEffect, useRef } from "react";

const ValuesComponent = ({ currentLanguage, classOptions }) => {
    const cardRefs = useRef([]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };

        const observerCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const index = entry.target.dataset.index;
                    entry.target.style.transitionDelay = `${index * 0.4}s`;
                    entry.target.classList.add("show");
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        cardRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        return () => {
            cardRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);
    const title = {
        en: "Our <span class='text-primary'>Working Values</span>",
        jp: "私たちの<span class='text-primary'>働く価値観</span>",
        cn: "我们的<span class='text-primary'>工作价值观</span>",
        ru: "Наши <span class='text-primary'>рабочие ценности</span>",
    };
    return (
        <section
            className={`container feature-section position-relative mt-10 ${classOptions}`}
        >
            <Link to="/philosophy">
                <SectionTitle
                    title={title[currentLanguage]}
                    classOption="text-center"
                    titleClassOption="values-title letterspace-wide"
                />
            </Link>
            <section className="mt-7 row d-flex justify-content-center">
                {PhilosophyData[1].cards.map((card, index) => (
                    <div
                        key={card.id}
                        className={`card card-shadow rounded-lg col-8 col-xl-3 fade-in ${card.classOption}`}
                        data-index={index}
                        ref={(el) => (cardRefs.current[index] = el)}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ minHeight: "160px" }}
                        >
                            <img
                                src={card.image}
                                alt="..."
                                style={{ maxWidth: "75%", height: "auto" }}
                            />
                        </div>
                        <div className="card-body text-center d-flex flex-column">
                            <h4 className="card-title mt-auto text-primary fw-bold letterspace-wide mb-3">
                                {card.title[currentLanguage]}
                            </h4>
                            <p className="card-text small-text black-color letterspace-light">
                                {card.content[currentLanguage]}
                            </p>
                        </div>
                    </div>
                ))}
            </section>
        </section>
    );
};

ValuesComponent.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    classOptions: PropTypes.string,
};

export default ValuesComponent;
