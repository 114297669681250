import React from "react";
import TeamCard from "../../components/team-card";
import TeamData from "../../data/team.json";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";

const TeamPageContainer = ({ currentLanguage }) => {
    const title = {
        en: "Engineers",
        jp: "エンジニアメンバー",
        cn: "工程师",
        ru: "Инженеры",
    };
    return (
        <div className="service-section section-py">
            <div className="container">
                <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n7">
                    {TeamData &&
                        TeamData.slice(0, 8).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col col-md-6 col-lg-4 col-xl-4 text-center mb-7"
                                >
                                    <TeamCard
                                        key={key}
                                        data={single}
                                        currentLanguage={currentLanguage}
                                    />
                                </div>
                            );
                        })}
                </div>
                <SectionTitle
                    classOption="title-section mt-10 ms-2"
                    title={title[currentLanguage]}
                />
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n7">
                    {TeamData &&
                        TeamData.slice(8, 16).map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col col-md-6 col-lg-4 col-xl-4 text-center mb-7"
                                >
                                    <TeamCard
                                        key={key}
                                        data={single}
                                        currentLanguage={currentLanguage}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

TeamPageContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default TeamPageContainer;
