import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import IconBox from "../../../components/icon-box";
import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";

const IconBoxContainer = ({ classOption, currentLanguage }) => {
    return (
        <div className={`feature-section position-relative ${classOption}`}>
            <img
                className="path-img"
                src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
                alt="images_not_found"
            />
            <div className="container custom-container">
                <div className="row g-0 align-items-center">
                    <div className="col-xl-8 col-lg-8 mx-auto mb-3 text-center">
                        <SectionTitle
                            classOption="title-section"
                            subTitle={
                                HomeData[6].iconBoxSection[0].subTitle[
                                    currentLanguage
                                ]
                            }
                            title={
                                HomeData[6].iconBoxSection[0].title[
                                    currentLanguage
                                ]
                            }
                            excerptClassOption="mb-10 sub-title"
                            excerpt={
                                HomeData[6].iconBoxSection[0].excerpt[
                                    currentLanguage
                                ]
                            }
                        />
                        <Button
                            classOption="btn btn-md btn-dark btn-hover-dark"
                            text="Get Started"
                            path="/about"
                        />
                    </div>

                    <div className="col-12">
                        <div id="grid" className="grid row mb-n7">
                            {HomeData[2].iconBox &&
                                HomeData[2].iconBox.map((single, key) => {
                                    return (
                                        <IconBox
                                            key={key}
                                            data={single}
                                            currentLanguage={currentLanguage}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

IconBoxContainer.propTypes = {
    classOption: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired,
};

IconBoxContainer.defaultProps = {
    classOption: "section-pb",
};

export default IconBoxContainer;
