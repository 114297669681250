import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Intro from "../../../components/intro";
import HomeData from "../../../data/home.json";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IntroContainer = ({ currentLanguage }) => {
    const images = HomeData[0].slider[0].image;
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animateTitle, setAnimateTitle] = useState(false);

    const timeoutRef = useRef(null);

    const clearExistingTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 850,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: () => {
            clearExistingTimeout();
            timeoutRef.current = setTimeout(() => {
                setAnimateTitle(true);
            }, 100);
        },
        afterChange: (current) => {
            clearExistingTimeout();
            setCurrentSlide(current);
            timeoutRef.current = setTimeout(() => {
                setAnimateTitle(false);
            }, 1000);
        },
    };

    return (
        <div className="section position-relative">
            <Slider {...sliderSettings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: "cover",
                                backgroundPosition:
                                    index === 1 ? "top" : "center",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: -1,
                                opacity: 1,
                                marginTop: "70px",
                            }}
                        ></div>
                        <Intro
                            data={HomeData[0].slider[0]}
                            currentLanguage={currentLanguage}
                            currentImageIndex={index}
                            animateTitle={animateTitle}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

IntroContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default IntroContainer;
