import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
import ContactData from "../data/contact.json";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const ContactPage = ({ currentLanguage, onLanguageSelected }) => {
    const location = useLocation();
    const [category, setCategory] = useState("");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const category = params.get("category");
        if (category) {
            setCategory(category);
        }
    }, [location.search]);

    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.contact.title[currentLanguage]}
                    description={SEOData.contact.description[currentLanguage]}
                    keywords={SEOData.contact.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={ContactData[2].pageBanner.title[currentLanguage]}
                        excerpt={
                            ContactData[2].pageBanner.excerpt[currentLanguage]
                        }
                        image="/images/contact/1.png"
                    />
                    <ContactContainer
                        currentLanguage={currentLanguage}
                        category={category}
                    />
                    <Footer currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ContactPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default ContactPage;
