import PropTypes from "prop-types";
import SocialIcon from "../social-icon";
import HomeData from "../../data/home.json";
import { Link } from "react-router-dom/cjs/react-router-dom";

const SocialIconContainer = ({ currentLanguage, classOption }) => {
    return (
        <div className={`container position-relative ${classOption}`}>
            <div className="title-section title-section mb-lg-10 pb-lg-1 px-5 px-md-0">
                <span className="sub-title">
                    {HomeData[11].blogSection[0].subtitle[currentLanguage]}
                </span>
                <h4
                    className="title mb-lg-0 letterspace-wide"
                    dangerouslySetInnerHTML={{
                        __html: HomeData[11].blogSection[0].title[
                            currentLanguage
                        ],
                    }}
                ></h4>
                <div className="pl-xl-5">
                    <p className="happy-customer mt-lg-3 pt-lg-3 mb-10 mb-lg-0">
                        {HomeData[11].blogSection[0].excerpt[currentLanguage]}
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 col-md-3 col-lg-2 rounded-lg p-2 mx-4 shadow mt-4 mt-lg-0">
                        <div className="d-flex justify-content-center text-center align-items-center h-100">
                            <a href="https://note.com/marvel_engineer/">
                                <div className="col-12 d-flex justify-content-center align-items-center text-center mt-auto h-100">
                                    <img
                                        src="/images/social/note.png"
                                        alt="Note"
                                        className="img-fluid w-75"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2 rounded-lg p-2 mx-4 shadow mt-4 mt-lg-0">
                        <div className="d-flex justify-content-center text-center align-items-center h-100">
                            <a href="https://www.wantedly.com/companies/company_2519627/stories">
                                <div className="col-12 d-flex justify-content-center align-items-center text-center mt-auto">
                                    <img
                                        src="/images/social/w.png"
                                        alt="Wantedly"
                                        className="img-fluid w-100"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2 rounded-lg p-2 mx-4 shadow mt-4 mt-lg-0">
                        <div className="d-flex justify-content-center text-center align-items-center h-100">
                            <a href="https://www.instagram.com/marvel.engineer_official/">
                                <div className="col-12 d-flex justify-content-center align-items-center text-center mt-auto">
                                    <img
                                        src="/images/social/IG.png"
                                        alt="Instagram"
                                        className="img-fluid w-100"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-4 col-md-3 col-lg-2 rounded-lg p-2 mx-4 shadow mt-4 mt-lg-0">
                        <div className="d-flex justify-content-center text-center align-items-center h-100">
                            <a href="https://www.facebook.com/Marvel.Tokyo.IT/">
                                <div className="col-12 d-flex justify-content-center align-items-center text-center mt-auto h">
                                    <img
                                        src="/images/social/facebook.png"
                                        alt="facebook"
                                        className="img-fluid w-75"
                                    />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SocialIconContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    classOption: PropTypes.string,
};

export default SocialIconContainer;
