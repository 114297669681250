import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const Brochure = ({ currentLanguage }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

    const fileUrl = "https://image.marvel.engineer/corporate%20profile.pdf";

    const brochure = {
        jp: "資料",
        en: "Brochure",
        cn: "资料",
        ru: "Брошюра",
    };

    const downloadFile = () => {
        setLoading(true);
        setError(null);

        try {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.setAttribute("download", "corporate profile.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        } catch (err) {
            console.error("Error downloading file: ", err);
            setError("Error downloading file");
            setLoading(false);
        }
    };

    const previewFile = () => {
        setLoading(true);
        setError(null);

        try {
            setPdfUrl(fileUrl);
            setShowModal(true);
            setLoading(false);
        } catch (err) {
            console.error("Error previewing file: ", err);
            setError("Error previewing file");
            setLoading(false);
        }
    };

    return (
        <div className="brochure-container">
            <div className="brochure-header">
                <h5>{brochure[currentLanguage]}</h5>

                <div className="button-group">
                    {/* Download Button */}
                    <button
                        className="btn btn-outline-success mx-2 download-btn"
                        onClick={downloadFile}
                        disabled={loading}
                    >
                        <i className="icofont-download"></i>
                    </button>

                    {/* Preview Button */}
                    <button
                        className="btn btn-outline-primary preview-btn"
                        onClick={previewFile}
                        disabled={loading}
                    >
                        <i className="icofont-eye"></i>
                    </button>
                </div>
            </div>

            {error && <p style={{ color: "red" }}>{error}</p>}

            {/* Modal for PDF Preview */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>PDF Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                        title="Preview File"
                    ></iframe>
                </Modal.Body>
            </Modal>
        </div>
    );
};

Brochure.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default Brochure;
