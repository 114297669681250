import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import ProjectsCarouselContainer from "../components/projects-carousel";
import PropTypes from "prop-types";
import AboutData from "../data/about.json";
import PresidentMessage from "../components/president-message";
import Timeline from "../components/timeline";
import CorporatePhilosophy from "../components/corporate-philosophy";
import ValuesComponent from "../components/values";
import PreviewContainer from "../components/preview";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const AboutPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.about.title[currentLanguage]}
                    description={SEOData.about.description[currentLanguage]}
                    keywords={SEOData.about.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={AboutData[0].banner[0].title[currentLanguage]}
                        excerpt={
                            AboutData[0].banner[0].excerpt[currentLanguage]
                        }
                        image="/images/banner/1.png"
                    />
                    <PresidentMessage currentLanguage={currentLanguage} />
                    <Timeline currentLanguage={currentLanguage} />
                    <PreviewContainer currentLanguage={currentLanguage} />
                    <ValuesComponent
                        currentLanguage={currentLanguage}
                        classOptions={"section-pt"}
                    />
                    <BrandContainer currentLanguage={currentLanguage} />
                    <AboutContainer currentLanguage={currentLanguage} />
                    <ServiceListContainer currentLanguage={currentLanguage} />
                    <TestimonialReverse currentLanguage={currentLanguage} />
                    <FunFactContainer
                        currentLanguage={currentLanguage}
                        classOption="mt-0 mt-lg-0"
                    />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

AboutPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default AboutPage;
