import PropTypes from "prop-types";
import AboutData from "../../data/about.json";

const Timeline = ({ currentLanguage }) => {
    return (
        <section className="timeline-wrapper">
            <div className="py-5">
                <div className="timeline">
                    {AboutData[4].timeline.map((item, index) => (
                        <div className="column" key={index}>
                            <div className="title">
                                <h1> {item.date} </h1>
                                <h2>{item.label[currentLanguage]}</h2>
                            </div>
                            <div className="description">
                                <p>{item.text[currentLanguage]}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

Timeline.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default Timeline;
