import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
import ServiceDetails from "./templates/service-details";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faq";
import BlogPage from "./pages/blog";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
import BlogDetailsPage from "./templates/blog-details";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogTag from "./templates/blog-tag";
import BlogCategory from "./templates/blog-category";
import ContactPage from "./pages/contact";
import RecruitPage from "./pages/recruit";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";
import React, { useState, useEffect } from "react";
import ProjectsPage from "./pages/projects";
import ProjectsSearchPage from "./pages/projects-search";
import ProjectDetails from "./pages/project-details";
import NewsPage from "./pages/news";
import OverviewPage from "./pages/overview";
import PhilosophyPage from "./pages/philosophy";
import Page404 from "./pages/page404";

const App = () => {
    const [theLanguage, setTheLanguage] = useState("en");

    useEffect(() => {
        const currentPath = window.location.pathname;
        const languageFromPath = currentPath.split("/")[1];

        const routeLangMap = {
            ja: "jp",
            en: "en",
            zh: "cn",
            ru: "ru",
        };

        // Detect language from URL path if present
        if (routeLangMap[languageFromPath]) {
            setTheLanguage(routeLangMap[languageFromPath]);
            document.body.setAttribute("lang", routeLangMap[languageFromPath]);
            document.documentElement.setAttribute(
                "lang",
                routeLangMap[languageFromPath] === "jp"
                    ? "ja"
                    : routeLangMap[languageFromPath]
            );
        } else {
            // Fallback to user language preference or default language
            const userLang = navigator.language.split("-")[0];
            const mappedLang = routeLangMap[userLang] || "en";
            setTheLanguage(mappedLang);
            document.body.setAttribute("lang", mappedLang);
            document.documentElement.setAttribute(
                "lang",
                mappedLang === "jp" ? "ja" : mappedLang
            );
        }
    }, []);

    const onLanguageSelected = (option) => {
        setTheLanguage(option);
        document.body.setAttribute("lang", option);
        if (option === "en")
            document.getElementsByTagName("html")[0].setAttribute("lang", "en");
        if (option === "jp")
            document.getElementsByTagName("html")[0].setAttribute("lang", "ja");
        if (option === "cn")
            document.getElementsByTagName("html")[0].setAttribute("lang", "zh");
        if (option === "ru")
            document.getElementsByTagName("html")[0].setAttribute("lang", "ru");
    };
    return (
        <Router>
            <NavScrollTop>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        exact
                        render={(props) => (
                            <HomePage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)"
                        render={(props) => (
                            <HomePage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/about"
                        render={(props) => (
                            <AboutPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/about"}`}
                        render={(props) => (
                            <AboutPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/overview"
                        render={(props) => (
                            <OverviewPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/overview"}`}
                        render={(props) => (
                            <OverviewPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/philosophy"
                        render={(props) => (
                            <PhilosophyPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/philosophy"}`}
                        render={(props) => (
                            <PhilosophyPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/service"
                        render={(props) => (
                            <ServicePage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/service"}`}
                        render={(props) => (
                            <ServicePage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/service-details/:id"
                        render={(props) => (
                            <ServiceDetails
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/service-details/:id"
                        }`}
                        render={(props) => (
                            <ServiceDetails
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    {/*
                    // プロジェクト事例が８個以上がないと、コメントした部分が無意味なので、
                    // プロジェクト事例が8個以上になったら、コメントを消してOK  
                    <Route
                        path={`${process.env.PUBLIC_URL + "/projects"}`}
                        render={(props) => (
                            <ProjectsPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/projects-search"}`}
                        render={(props) => (
                            <ProjectsSearchPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    */}
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/project-details/:id"
                        render={(props) => (
                            <ProjectDetails
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/project-details/:id"
                        }`}
                        render={(props) => (
                            <ProjectDetails
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/team"
                        render={(props) => (
                            <TeamPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/team"}`}
                        render={(props) => (
                            <TeamPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/faq"
                        render={(props) => (
                            <FaqPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/faq"}`}
                        render={(props) => (
                            <FaqPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/contact"
                        render={(props) => (
                            <ContactPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/contact"}`}
                        render={(props) => (
                            <ContactPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/news"
                        render={(props) => (
                            <NewsPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/news"}`}
                        render={(props) => (
                            <NewsPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/:lang(ja|en|zh|ru)/recruit"
                        render={(props) => (
                            <RecruitPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/recruit"}`}
                        render={(props) => (
                            <RecruitPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    {/* <Route
                        path={`${process.env.PUBLIC_URL + "/blog"}`}
                        render={(props) => (
                            <BlogPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/blog-left-sidebar"
                        }`}
                        render={(props) => (
                            <BlogLeftSidebarPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${
                            process.env.PUBLIC_URL + "/blog-right-sidebar"
                        }`}
                        render={(props) => (
                            <BlogRightSidebarPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
                        render={(props) => (
                            <BlogDetailsPage
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/author/:author"}`}
                        render={(props) => (
                            <BlogAuthor
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                        render={(props) => (
                            <BlogDate
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                        render={(props) => (
                            <BlogTag
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
                        render={(props) => (
                            <BlogCategory
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    /> */}
                    <Route
                        path="*"
                        render={(props) => (
                            <Page404
                                {...props}
                                onLanguageSelected={onLanguageSelected}
                                currentLanguage={theLanguage}
                            />
                        )}
                    />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;
