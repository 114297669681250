import PropTypes from "prop-types";
import AboutData from "../../data/about.json";

const PresidentMessage = ({ currentLanguage, classOptions }) => {
    return (
        <div className={`container ${classOptions}`}>
            <div className="row d-flex justify-content-center mt-10 px-5 px-md-0">
                <div className="col-md-12 col-lg-11 col-xl-11">
                    <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                        <div className="flex-shrink-0 text-center text-lg-star">
                            <img
                                src="/images/team/kawabata.jpg"
                                className="rounded-circle mb-2 mb-md-4 mb-lg-0 shadow-2"
                                alt="kawabata avatar"
                                width="160"
                                height="160"
                            />
                            <p
                                className="text-center mt-0 mt-md-2 presidentmessage-title"
                                dangerouslySetInnerHTML={{
                                    __html: AboutData[3].representativeMessage
                                        .name[currentLanguage],
                                }}
                            ></p>
                        </div>
                        <div className="flex-grow-1 ms-0 ms-lg-4 ps-0 ps-lg-3">
                            <figure>
                                <blockquote className="blockquote mb-4">
                                    <p>
                                        <i className="icofont-quote-left text-primary me-2"></i>
                                        <span className="font-italic">
                                            <h2 className="text-primary fw-bold letterspace-wide mb-9 ">
                                                {
                                                    AboutData[3]
                                                        .representativeMessage
                                                        .title[currentLanguage]
                                                }
                                            </h2>
                                            <p className="presidentmessage-text">
                                                {
                                                    AboutData[3]
                                                        .representativeMessage
                                                        .first[currentLanguage]
                                                }
                                            </p>
                                            <p className="presidentmessage-text">
                                                {
                                                    AboutData[3]
                                                        .representativeMessage
                                                        .second[currentLanguage]
                                                }
                                            </p>
                                            <p className="presidentmessage-text">
                                                {
                                                    AboutData[3]
                                                        .representativeMessage
                                                        .third[currentLanguage]
                                                }
                                            </p>
                                            <p className="presidentmessage-text">
                                                {
                                                    AboutData[3]
                                                        .representativeMessage
                                                        .fourth[currentLanguage]
                                                }
                                            </p>
                                        </span>
                                    </p>
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    Miranda Smith in{" "}
                                    <cite title="Source Title">
                                        The Guardian
                                    </cite>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PresidentMessage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    classOptions: PropTypes.string,
};

export default PresidentMessage;
