import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import SearchBar from "../components/searchbar";
import ProjectsSearchContainer from "../containers/projectsSearch";
import ProjectData from "../data/projects.json";
import ProjectDetailsContainer from "../components/projectdetails";
import NewsletterArea from "../containers/global/newsletter";
import CookieConsentForm from "../components/cookie-consent";

const ProjectDetails = ({
    match: {
        params: { id },
    },
    currentLanguage,
    onLanguageSelected,
}) => {
    const projectId = parseInt(id, 10);
    const data = ProjectData[0].slider.filter(
        (project) => project.id === projectId
    );
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Buznex – About" />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <ProjectDetailsContainer
                        currentLanguage={currentLanguage}
                        data={data[0]}
                    />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ProjectDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default ProjectDetails;
