import PropTypes from "prop-types";

const SectionTitle = ({
    subTitle,
    title,
    excerpt,
    sidebody,
    classOption,
    excerptClassOption,
    titleClassOption,
}) => {
    return (
        <div className={`${classOption}`}>
            <span className="sub-title">{subTitle}</span>
            <div className="title-container">
                <h3
                    className={`${titleClassOption} title`}
                    dangerouslySetInnerHTML={{ __html: title }}
                ></h3>
                {sidebody && (
                    <span
                        className="title-sidebody"
                        dangerouslySetInnerHTML={{ __html: sidebody }}
                    ></span>
                )}
            </div>
            <p
                className={`${excerptClassOption}`}
                dangerouslySetInnerHTML={{ __html: excerpt }}
            ></p>
        </div>
    );
};

SectionTitle.propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    sidebody: PropTypes.string,
    classOption: PropTypes.string,
    excerptClassOption: PropTypes.string,
    titleClassOption: PropTypes.string,
};
SectionTitle.defaultProps = {
    classOption: "section-title",
};

export default SectionTitle;
