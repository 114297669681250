import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutContainer from "../containers/about";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import PhilosophyData from "../data/philosophy.json";
import PresidentMessage from "../components/president-message";
import CorporatePhilosophy from "../components/corporate-philosophy";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const PhilosophyPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.philosophy.title[currentLanguage]}
                    description={
                        SEOData.philosophy.description[currentLanguage]
                    }
                    keywords={SEOData.philosophy.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={
                            PhilosophyData[0].banner[0].title[currentLanguage]
                        }
                        excerpt={
                            PhilosophyData[0].banner[0].excerpt[currentLanguage]
                        }
                        image="/images/banner/1.png"
                    />
                    <CorporatePhilosophy currentLanguage={currentLanguage} />
                    <PresidentMessage
                        classOptions={"section-pt-md"}
                        currentLanguage={currentLanguage}
                    />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

PhilosophyPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default PhilosophyPage;
