import React from "react";
import PropTypes from "prop-types";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ServiceData from "../data/service.json";
import ServiceDetailsContainer from "../containers/service/service-details";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactDialogForm from "../components/contact-dialog-form";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const ServiceDetails = ({
    match: {
        params: { id },
    },
    currentLanguage,
    onLanguageSelected,
}) => {
    const serviceId = parseInt(id, 10);
    const data = ServiceData.filter((service) => service.id === serviceId);
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.service_details.title[currentLanguage]}
                    description={
                        SEOData.service_details.description[currentLanguage]
                    }
                    keywords={SEOData.service_details.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={data[0]?.title[currentLanguage]}
                        excerpt={data[0]?.banner_excerpt[currentLanguage]}
                        image="/images/service/2.png"
                    />
                    <ServiceDetailsContainer
                        currentLanguage={currentLanguage}
                        data={data[0]}
                    />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

ServiceDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default ServiceDetails;
