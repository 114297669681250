import React from "react";
import AccordionWrap from "../../components/accrodion/AccordionWrap";
import PropTypes from "prop-types";

const AccordionContainer = ({ currentLanguage }) => {
    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10">
                        <AccordionWrap currentLanguage={currentLanguage} />
                    </div>
                </div>
            </div>
        </div>
    );
};

AccordionContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default AccordionContainer;
