import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { flatDeep, slugify, containsObject } from "../../../utils";

const ServiceCate = ({ data, currentLanguage }) => {
    const cats = data.map((item) => {
        return item.categories;
    });
    let singleCatArray = flatDeep(cats).filter((item) => Boolean(item));
    let categories = [];
    singleCatArray.forEach((cat) => {
        const obj = {
            title: cat[currentLanguage].trim(),
            slug: slugify(cat[currentLanguage]),
            count: 1,
        };
        const objIndex = containsObject(obj, categories);
        if (objIndex !== -1) {
            const prevCount = categories[objIndex].count;
            categories[objIndex] = {
                title: cat[currentLanguage].trim(),
                slug: slugify(cat[currentLanguage]),
                count: prevCount + 1,
            };
        } else {
            categories.push(obj);
        }
    });

    return (
        <div className="sidbar-menu">
            <ul>
                {data.map((single, i) => {
                    return (
                        <li key={i}>
                            <NavLink
                                activeClassName="active"
                                to={
                                    process.env.PUBLIC_URL +
                                    `/service-details/${slugify(single.id)}`
                                }
                            >
                                <i
                                    className={`small_logo me-3 ${single.icon_class}`}
                                />
                                {single.title[currentLanguage]}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

ServiceCate.propTypes = {
    data: PropTypes.array,
    currentLanguage: PropTypes.string.isRequired,
};

export default ServiceCate;
