import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SidebarCategory from "../sidebar/sidbar-category";
// プロジェクト事例が８個以上がないと、コメントした部分が無意味なので、
// プロジェクト事例が8個以上になったら、コメントを消してOK
const ProjectDetailsContainer = ({ currentLanguage, data }) => {
    const articleText = data.article_text[currentLanguage];

    const firstParagraphMatch = articleText.match(/<p>.*?<\/p><p>.*?<\/p>/);
    const firstParagraph = firstParagraphMatch ? firstParagraphMatch[0] : "";
    const otherContent = articleText.replace(firstParagraph, "");
    return (
        <div className="position-relative section-pt mt-7">
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <img
                        src={process.env.PUBLIC_URL + data.thumbnail}
                        alt={data.title[currentLanguage]}
                        className="mx-auto col-12 col-md-5 rounded"
                    />
                    <div className="col-12 col-md-7">
                        <div className="text-center">
                            <h2>{data.title[currentLanguage]}</h2>
                        </div>
                        <div className="mt-3 ms-3">
                            {data.subtitle[currentLanguage]}
                        </div>
                        <div className="mt-3 ms-3">{data.site}</div>
                        <div className="mt-5 article-text">
                            <div
                                className="first-paragraph"
                                dangerouslySetInnerHTML={{
                                    __html: firstParagraph,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 article-text mb-7">
                    <div
                        className="other-content"
                        dangerouslySetInnerHTML={{
                            __html: otherContent,
                        }}
                    ></div>
                </div>
                {/* <div className="social-tags d-sm-flex justify-content-between align-items-center">
                    <p className="mb-4 mb-sm-0 d-flex align-items-center">
                        <i className="fa fa-tags"></i>
                        <SidebarCategory data={data} />
                    </p>
                </div> */}
            </div>
        </div>
    );
};

ProjectDetailsContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    data: PropTypes.object,
};

export default ProjectDetailsContainer;
