import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import PageBanner from "../containers/global/page-banner";
import NewsContainer from "../components/news";
import NewsletterArea from "../containers/global/newsletter";
import NewsData from "../data/news.json";
import SocialIconContainer from "../components/social";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const NewsPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.news.title[currentLanguage]}
                    description={SEOData.news.description[currentLanguage]}
                    keywords={SEOData.news.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={NewsData[1].title[currentLanguage]}
                        excerpt={NewsData[1].excerpt[currentLanguage]}
                        image="/images/service/12.png"
                    />
                    <NewsContainer currentLanguage={currentLanguage} />
                    <SocialIconContainer
                        currentLanguage={currentLanguage}
                        classOption={"section-py"}
                    />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

NewsPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default NewsPage;
