import React, { useState } from "react";
import PropTypes from "prop-types";
import { ChevronDownIcon, LanguageIcon } from "@heroicons/react/20/solid";
import _ from "lodash";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function LanguageDropdown({ currentLanguage, onSelect }) {
    const [isOpen, setIsOpen] = useState(false);

    const languageTable = {
        en: {
            en: "English",
            jp: "Japanese",
            cn: "Chinese",
            ru: "Russian",
        },
        jp: {
            en: "英語",
            jp: "日本語",
            cn: "中国語",
            ru: "ロシア語",
        },
        cn: {
            en: "英语",
            jp: "日语",
            cn: "中文简体",
            ru: "俄语",
        },
        ru: {
            en: "Английский",
            jp: "Японский",
            cn: "Китайский",
            ru: "Русский",
        },
    };

    const handleOptionSelect = (option) => {
        onSelect(option);
        setIsOpen(false); // Close dropdown after selection
    };

    const options = _.entries(languageTable[currentLanguage]);

    return (
        <div className="language-dropdown">
            <button onClick={() => setIsOpen(!isOpen)} className="button">
                {languageTable[currentLanguage][currentLanguage]}
                <LanguageIcon className="icon" aria-hidden="true" />
                <ChevronDownIcon className="icon" aria-hidden="true" />
            </button>

            {isOpen && (
                <div className="language-dropdown-menu">
                    <div className="py-1">
                        {options.map((option) => {
                            const selected = option[0] === currentLanguage;
                            const nativeVersion =
                                languageTable[option[0]][option[0]];
                            return (
                                <div
                                    key={option[0]}
                                    className={classNames(
                                        "dropdown-item",
                                        selected && "selected"
                                    )}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() =>
                                        handleOptionSelect(option[0])
                                    }
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "Enter" ||
                                            e.key === " "
                                        ) {
                                            handleOptionSelect(option[0]);
                                        }
                                    }}
                                >
                                    {option[1]} ({nativeVersion})
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

LanguageDropdown.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};
