import React from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../accrodion";
import PropTypes from "prop-types";
import FaqData from "../../data/faq.json";

const AccordionWrap = ({ currentLanguage }) => {
    return (
        <Accordion classOption="accordion-style2 no-bg">
            {FaqData[0].faq.map((item, index) => (
                <AccordionItem key={index} id={item.id}>
                    <AccordionTitle id={item.id}>
                        <span className="question-tag">Q:</span>
                        {item.question[currentLanguage]}
                    </AccordionTitle>
                    <AccordionContent id={item.id}>
                        {item.answer[currentLanguage]}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

AccordionWrap.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default AccordionWrap;
