import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";
import AboutData from "../../data/about.json";
import PropTypes from "prop-types";

const AboutContainer = ({ currentLanguage }) => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative px-5 px-md-0">
            <div className="container">
                <div className="row mb-n7 align-items-center">
                    <div className="col-xl-6 col-lg-7 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={
                                    AboutData[1].aboutContainer[0].subtitle[
                                        currentLanguage
                                    ]
                                }
                                title={
                                    AboutData[1].aboutContainer[0].title[
                                        currentLanguage
                                    ]
                                }
                                titleClassOption="letterspace-wide"
                            />
                            <span className="date-meta">
                                Since 2020 <span className="hr"></span>
                            </span>
                            <p className="presidentmessage-text mb-5">
                                {
                                    AboutData[1].aboutContainer[0].content[
                                        currentLanguage
                                    ]
                                }
                            </p>
                            <p className="presidentmessage-text mb-2">
                                {
                                    AboutData[1].aboutContainer[0].item1[
                                        currentLanguage
                                    ]
                                }
                            </p>
                            <p className="presidentmessage-text mb-2">
                                {
                                    AboutData[1].aboutContainer[0].item2[
                                        currentLanguage
                                    ]
                                }
                            </p>
                            <Button
                                classOption="btn btn-md btn-dark btn-hover-dark"
                                text="Learn more"
                                path="/about"
                            />
                        </div>
                    </div>
                    <div className="col-xl-5 order-first order-lg-first col-lg-5 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/2.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AboutContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default AboutContainer;
