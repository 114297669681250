import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Team from "../../../components/team";
import HomeData from "../../../data/home.json";

const TeamContainer = ({ classOption, currentLanguage }) => {
    return (
        <div
            className={`team-section overflow-hidden ${classOption} px-5 px-md-0`}
        >
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="team-content-wrap">
                            <SectionTitle
                                classOption="title-section"
                                subTitle={
                                    HomeData[10].teamSection[0].subtitle[
                                        currentLanguage
                                    ]
                                }
                                title={
                                    HomeData[10].teamSection[0].title[
                                        currentLanguage
                                    ]
                                }
                                excerptClassOption="happy-customer mb-5"
                                excerpt={
                                    HomeData[10].teamSection[0].excerpt[
                                        currentLanguage
                                    ]
                                }
                                titleClassOption={"letterspace-wide"}
                            />

                            <Button
                                classOption="btn btn-md btn-dark btn-hover-dark"
                                text="View more"
                                path="/team"
                            />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="new-team-members">
                            {HomeData[5].team &&
                                HomeData[5].team.map((single, key) => {
                                    return (
                                        <Team
                                            key={key}
                                            data={single}
                                            currentLanguage={currentLanguage}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

TeamContainer.propTypes = {
    classOption: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired,
};

TeamContainer.defaultProps = {
    classOption: "section-pb",
};

export default TeamContainer;
