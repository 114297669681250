import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TeamPageContainer from "../containers/team-page-container";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ContactDialogForm from "../components/contact-dialog-form";
import PropTypes from "prop-types";
import HomeData from "../data/home.json";
import SEOData from "../data/seo.json";
import CookieConsentForm from "../components/cookie-consent";

const TeamPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <SEO
                    title={SEOData.team.title[currentLanguage]}
                    description={SEOData.team.description[currentLanguage]}
                    keywords={SEOData.team.keywords[currentLanguage]}
                    currentLanguage={currentLanguage}
                />
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title={
                            HomeData[14].teamBanner[0].title[currentLanguage]
                        }
                        excerpt={
                            HomeData[14].teamBanner[0].excerpt[currentLanguage]
                        }
                        image="/images/team/5.png"
                    />
                    <TeamPageContainer currentLanguage={currentLanguage} />
                    <NewsletterArea currentLanguage={currentLanguage} />
                    <Footer currentLanguage={currentLanguage} />
                    <ContactDialogForm currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

TeamPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default TeamPage;
