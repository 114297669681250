import React from "react";
import Slider from "react-slick";
import HomeData from "../../../data/home.json";
import Brand from "../../../components/brand/index";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../../../components/section-title";

const BrandContainer = ({ currentLanguage }) => {
    const sliderSettings = {
        infinite: true,
        speed: 3500,
        autoplay: true,
        autoplaySpeed: 3500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="brand-section section-py">
            <SectionTitle
                title={HomeData[1].title[currentLanguage]}
                classOption="text-center"
                titleClassOption="values-title letterspace-wide mb-9"
            />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Slider {...sliderSettings} className="brand-carousel">
                            {HomeData[1].brand &&
                                HomeData[1].brand.map((single, key) => {
                                    return (
                                        <div key={key}>
                                            <Brand
                                                key={key}
                                                data={single}
                                                currentLanguage={
                                                    currentLanguage
                                                }
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

BrandContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default BrandContainer;
