import PropTypes from "prop-types";
import SectionTitle from "../section-title";
import OverviewInformation from "../../data/overview.json";

const OverviewContainer = ({ currentLanguage }) => {
    return (
        <div className="container company-overview-container">
            <div className="d-flex w-100 justify-content-center">
                <img
                    src={process.env.PUBLIC_URL + "/images/logo/logo.png"}
                    style={{ width: "30%", marginBottom: "80px" }}
                    className=""
                    alt="img"
                />
            </div>

            <hr style={{ width: "70%", margin: "16px auto" }} />
            {OverviewInformation[0].infoitems.map((item, index) => (
                <>
                    <div className="row d-flex justify-content-center">
                        <div className="col-3 col-lg-2 letterspace-wide main-text">
                            {item.title[currentLanguage]}
                        </div>
                        　
                        <div
                            className="col-8 col-lg-4 letterspace-wide main-text"
                            dangerouslySetInnerHTML={{
                                __html: item.label[currentLanguage],
                            }}
                        ></div>
                    </div>
                    <hr style={{ width: "70%", margin: "16px auto" }} />
                </>
            ))}
        </div>
    );
};

OverviewContainer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default OverviewContainer;
